.root {
    margin-top: 60px;
}

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    padding: 0 16px;

    @media (min-width: 640px) {
        padding: 0 32px 0 32px;
    }    

    @media (min-width: 840px) {
        padding: 20px 48px 0 48px;
    }
}

.safetyBeforeAll {
    font-size: 2rem;
}

.assetSecurity {
    text-align: center;
    font-size: 1.2rem;
    max-width: 650px;

    margin: 16px 0 40px 0;
}

.auditorsAndScore {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    @media (min-width: 1280px) {
        flex-direction: row;
        gap: 32px;
    }
}

.logos {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media (min-width: 640px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 840px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.logoContainer {
    position: relative;
    width: 100%;
    max-width: 142px;
    display: flex;
}

.logo, .logoHovered {
    width: 100%;
    transition: opacity 250ms linear;
}

.logoHovered {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-bg-secondary);
    text-decoration: none;
    overflow: hidden;
}

.auditor {
    padding: 16px;
    cursor: pointer;
    border-radius: 16px;
    height: 142px;

    hr {
        width: 100%;
        border: 0;
        height: 1px;
        background-color: var(--color-icon-bg);
        margin: 16px 0;
    }

    &:hover {
        .arrow {
            color: #3A78FF;
        }

        .logoHovered {
            opacity: 1;
        }
    }
}

.otherAuditors {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 142px;
    cursor: pointer;
    border-radius: 16px;
    border-style: dashed;
    border-color: var(--color-card-border);

    @media (min-width: 640px) {
        grid-column: span 2;
    }

    @media (min-width: 840px) {
        grid-column: span 1;
    }
}

.otherAuditsLink {
    text-align: center;
}

.auditorLogoContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.audits {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    p {
        font-size: 0.8rem;
        line-height: 21px;
    }

    span {
        font-weight: 600;
        color: white;
        margin-right: 8px;
    }

    @media (min-width: 840px) {
        font-size: 0.875rem;
    }
}

.cardScore {
    width: 100%;
    border-radius: 24px;
    padding: 24px;

    @media (min-width: 840px) {
        padding: 24px 48px 24px 48px;
    }

    @media (min-width: 1280px) {
        padding: 24px 24px 16px 24px;
        width: 375px;
    }
}

.cardScoreContent {
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @media (min-width: 640px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: 1280px) {
        flex-direction: column;
    }
}

.securityScore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 140px;
    width: 220px;

    background-image: url('./assets/score.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    text-align: center;

    span {
        color: var(--color-text-secondary);
        text-align: center;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem;
        margin-top: 6px;
        text-decoration: none;
    }

    @media (min-width: 1280px) {
        height: 180px;
        width: 180px;
    }
}

.scored {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;

    span {
        color: white;
    }
    
    @media (min-width: 640px) {
        text-align: unset;
    }

    @media (min-width: 1280px) {
        font-size: 0.875rem;
        text-align: center;
    }
    
    a {
        text-decoration: none;
        color: var(--color-primary);
    }
}

.linkWrapper {
    text-align: center;

    a {
        text-decoration: none;
        color: var(--color-primary);
    }
    
    @media (min-width: 640px) {
        text-align: unset;
    }

    @media (min-width: 1280px) {
        text-align: center;
    }
}

.auditCompleted {
    color: var(--color-text-secondary);
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    flex: 1;
}

.safetyScoreMobile {
    display: flex;

    @media (min-width: 1280px) {
        display: none;
    }
}

.safetyScoreDesktop {
    display: none;

    @media (min-width: 1280px) {
        display: flex;
    }
}

.arrow {
    flex-shrink: 0;
    color: #A9ABC7;
    transition: color 250ms linear;
}
