.root {
    margin-top: 60px;
    margin-bottom: 124px;
    @media (min-width: 640px) {
        margin-bottom: 100px;
    }
    @media (min-width: 840px) {
        margin-top: 80px;
    }
    @media (min-width: 1280px) {
        margin-top: 100px;
    }
}

.list {
    @media (min-width: 840px) {
        display: flex;
    }
}

.benefitItem {
    background-color: var(--color-bg-secondary);
    border-radius: 8px;
    padding: 24px;
    border: 1px solid var(--color-card-border);

    &+& {
        margin-top: 16px;
        @media (min-width: 840px) {
            margin-top: 0;
            margin-left: 24px;
        }
    }

    @media (min-width: 640px) {
        display: flex;
        align-items: center;
    }

    @media (min-width: 840px) {
        width: 33%;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 24px;
    }
}

.icon {
    margin-bottom: 24px;
    @media (min-width: 640px) {
        margin-bottom: 0;
        margin-right: 24px;
    }
    @media (min-width: 840px) {
        margin-right: 0;
        margin-bottom: 85px;
    }
}
.title {
    font-size: 24px;
    margin-bottom: 8px;
}
.text {
    color: var(--color-text-secondary);
}
