.headerNavLinksWrapper {
    flex-direction: column;
    margin-bottom: 10px;
}
.headerLink {
    padding-top: 21px;
    padding-bottom: 21px;
    position: relative;
    display: block;

    &+& {
        border-top: 1px solid var(--color-bg-secondary);
    }
    
    &:after {
        content: '';
        position: absolute;
        right: 0;
        background-image: url(./assets/iconArrow.svg);
        width: 5px;
        height: 8px;
        color: white;
        top: 50%;
        transform: translateY(-50%);
    }
}
.btn {
    width: 100%;
    text-align: center;
}