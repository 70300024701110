.root {
  width: 100vw;
  background-color: var(--color-banner-bg);
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.limit {
  position: relative;
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;

  span {
    margin-left: auto;
  }

  a {
    color: white;
  }
}

.close {
  margin-right: 14px;
  margin-left: auto;
  min-height: 20px;
  min-width: 20px;
}
