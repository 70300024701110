@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('../fonts/ProximaNova-Regular.eot');
    src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNova-Regular.woff') format('woff'),
        url('../fonts/ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('../fonts/ProximaNova-Semibold.eot');
    src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Semibold.woff') format('woff'),
        url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inconsolata';
    src: url('../fonts/Inconsolata-SemiBold.eot');
    src: url('../fonts/Inconsolata-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Inconsolata-SemiBold.woff2') format('woff2'),
    url('../fonts/Inconsolata-SemiBold.woff') format('woff'),
    url('../fonts/Inconsolata-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeue';
    src: url('../fonts/BebasNeue-Regular.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}