.root {
    padding-top: 50px;
    @media (min-width: 640px) {
        padding-top: 60px;
    }
    @media (min-width: 840px) {
        padding-top: 108px;
    }
    @media (min-width: 1280px) {
        display: flex;
        flex-direction: column;
        padding-top: 64px;
    }
}

.btn {
    margin-top: 24px;
    border: none;
    display: inline-block;
    transition: background-color .3s, border-color .3s, color .3s;
    color: white;
    text-decoration: none;
    padding: 12px 32px;
    background-color: var(--color-primary);
    border-radius: 8px;

    &:hover {
        background-color: var(--color-primary-hover);
    }

    &:active {
        background-color: var(--color-primary-active);
    }

}

.totalWrapper {
    border-radius: 16px;
    background: rgba(24, 29, 42, 0.10);
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 1px 0px #21293A inset, 2px 3px 3px -3px #5F5A88 inset;
    backdrop-filter: blur(50px);
    padding: 16px 16px;
    width: 100%;
    
    @media (min-width: 640px) {
        padding: 16px 24px;
    }
    
    @media (min-width: 1280px) {
        padding: 16px 64px;
    }
}

.totalList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 640px) {
        flex-direction: row;
        align-items: center;
    }

    @media (min-width: 1280px) {
        align-items: stretch;
    }
}

.totalItem {

    &:not(&:first-child) {
        border-top: 1px solid var(--color-card-border);
        padding-top: 12px;
        margin-top: 16px;
        padding-top: 16px;

        @media (min-width: 640px) {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
    }
}

.divider {
    @media (min-width: 640px) {
        display: block;
        width: 1px;
        height: 60px;
        background-color: var(--color-bg-secondary-transparent);
    }
    @media (min-width: 840px) {
        height: 70px;
    }
}

.totalTitle {
    color: var(--color-text-secondary);
    font-size: 14px;
    margin-bottom: 8px;
}

.totalSum {
    color: var(--color-text);
    font-family: var(--font-bold);
    font-size: 24px;

    @media (min-width: 640px) {
        font-size: 18px;
    }

    @media (min-width: 840px) {
        font-size: 24px;
    }
}

.launchBtnDesktop {
    display: none;
    @media (min-width: 1280px) {
        display: inline-block;
    }
}

.marketsWrapper {
    margin-top: 24px;
    border-radius: 24px;
    padding: 0 4px 0 4px;
    @media (min-width: 640px) {
        box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 1px 0px #21293A inset, 2px 3px 3px -3px #5F5A88 inset;
        background: rgba(24, 29, 42, 0.50);
        backdrop-filter: blur(50px);
        padding: 20px 32px 0 32px;
    }
    @media (min-width: 840px) {
        padding: 40px 24px 0 24px;
    }
    @media (min-width: 1280px) {
        padding: 40px 64px 0 64px;
        flex-grow: 1;
    }
}

.marketLabelsDesktop {
    display: none;
    @media (min-width: 640px) {
        display: flex;
        padding-bottom: 16px;
    }
}

.marketLabelDesktopItem {
    width: 20%;
    font-size: 14px;
    color: var(--color-text-secondary);
    &:not(&:first-child) {
        text-align: right;
    }
}

.marketItem {
    border-radius: 8px;
    padding: 12px 8px;
    font-size: 14px;

    &+& {
        margin-top: 16px;
    }
    
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 1px 0px #21293A inset, 2px 3px 3px -3px #5F5A88 inset;
    background: rgba(24, 29, 42, 0.50);

    @media (min-width: 640px) {
        box-shadow: unset;
        background: unset;
        display: flex;
        padding: 24px 0;
    }
}
.marketItemSymbol {
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    @media (min-width: 640px) {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        width: 20%;
    }
}
.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    @media (min-width: 640px) {
        width: 32px;
        height: 32px;
    }
}
.marketItemLabel {
    color: var(--color-text-secondary);
    font-size: 12px;

    @media (min-width: 640px) {
        display: none;
    }
}
.marketItemValuesWrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.marketItemValue {
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    width: 25%;
    font-size: 0.875rem;

    &:not(&:first-child) {
        text-align: right;
    }
    @media (min-width: 640px) {
        text-align: right;
        padding-left: 12px;
        font-size: 1rem;
    }
}

.btnWrapper {
    display: flex;
    justify-content: center;
    padding: 28px;

    a {
        text-decoration: none;
        color: var(--color-primary)
    }
}