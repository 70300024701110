@import "../../assets/styles/media.css";

.root {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 640px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (min-width: 840px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1280px) {
        padding-left: 48px;
        padding-right: 48px;
        /* 1184 = 1280 - padding*2 */
        margin-left: auto;
        margin-right: auto;
    }
}