@import "fonts.css";
@import "variables.css";
@import "media.css";

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, span {
  margin: 0;
}

h2 {
  font-size: 1.5rem;

  @media (min-width: 1280px) {
    font-size: 2rem;
  }
}

p {
  color: var(--color-text-secondary);
  font-size: 1rem;

  @media (min-width: 1280px) {
    font-size: 1.2rem;
  }
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

body {
  margin: 0;
  font-family: var(--font-default);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-bg);
  color: white;
  font-size: 16px;
  line-height: 1.5;
}